import React from 'react';
import { Table } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import styles from '../../Pages/ControleFinanceiro/css/Tabela.module.css';
import stylesButton from '../../Pages/ControleFinanceiro/css/Button.module.css';
import styleTabela from '../../Pages/ControleFinanceiro/css/Tabela.module.css';
import stylesTipografia from '../../Pages/ControleFinanceiro/css/Tipografia.module.css';
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from 'react-table';
import GlobalFilter from '../Helper/GlobalFilter';

import {
  deParaMes,
  formataData,
  formataMoeda,
  formataMoedaCF,
} from '../Helper/DateUtilities';
import ModalCadastroMovimento from './ModalCadastroMovimento';
import ModalExcluiMov from './ModalExcluiMov';
import isMobile from '../Helper/Mobile';
import MovimentosListFatura from './MovimentoListFatura';
import { useNavigate } from 'react-router';
import ModalTransf from './ModalTransf';

const MovimentosList = ({
  data,
  dataFatura,
  filtro,
  totalDespesa,
  totalReceita,
  totalInvestimentos,
  hide,
}) => {
  const navigate = useNavigate();
  const mobile = isMobile();
  const [show, setShow] = React.useState(false);
  const [showExclusao, setShowExclusao] = React.useState(false);
  const [showTransf, setShowTransf] = React.useState(false);
  const [idMovimento, setIdMovimento] = React.useState(null);
  const [idMovimentoDetalhe, setIdMovimentoDetalhe] = React.useState(null);
  const [dataMovimento, setDataMovimento] = React.useState(null);
  const [descricao, setDescricao] = React.useState(null);
  const [categoria, setCategoria] = React.useState(null);
  const [conta, setConta] = React.useState(null);
  const [cartao, setCartao] = React.useState(null);
  const [situacao, setSituacao] = React.useState(null);
  const [valor, setValor] = React.useState(null);
  const [formaPagamento, setFormaPagamento] = React.useState(null);
  const [totalParcelas, setTotalParcelas] = React.useState(null);
  const [acao, setAcao] = React.useState(null);
  const [tipoPagamento, setTipoPagamento] = React.useState(null);
  const [tipoLancamento, setTipoLancamento] = React.useState(null);

  const columns = React.useMemo(
    () => [
      { Header: 'Tipo', accessor: 'tipo' },
      { Header: 'Dia', accessor: 'data_compra' },
      { Header: 'Descrição', accessor: 'descricao' },
      { Header: 'Categoria', accessor: 'categoria' },
      { Header: 'Valor', accessor: 'valor' },
      { Header: 'Vencimento', accessor: 'data_vencimento' },
      { Header: 'fg_forma', accessor: 'fg_forma' },
      { Header: 'Forma', accessor: 'forma' },
      { Header: 'Pagamento', accessor: 'id_situacao' },
      { Header: 'id', accessor: 'id' },
      { Header: 'id_mov', accessor: 'id_mov' },
      { Header: 'id_conta', accessor: 'id_conta' },
      { Header: 'id_cartao', accessor: 'id_cartao' },
      { Header: 'conta', accessor: 'conta' },
      { Header: 'cartao', accessor: 'cartao' },
      { Header: 'Parcela', accessor: 'parcela' },
      { Header: 'data_pagamento', accessor: 'data_pagamento' },

      { Header: 'id_categoria', accessor: 'id_categoria' },
      { Header: 'tipo_pagamento', accessor: 'tipo_pagamento' },
      { Header: 'cor', accessor: 'cor' },
    ],
    [],
  );

  const initialState = {
    hiddenColumns: [
      // 'id',
      // 'id_mov',
      // 'id_conta',
      // 'id_cartao',
      // 'fg_forma',
      // 'id_categoria',
      // 'tipo_pagamento',
      // 'data_pagamento',
      // 'parcela',
      // 'conta',
      // 'cartao',
    ],
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    state,
    setGlobalFilter,
    prepareRow,
  } = useTable({ columns, data, initialState }, useGlobalFilter, useSortBy);

  const { globalFilter } = state;

  const showModal = (event) => {
    event.preventDefault();
    setShow(true);
  };

  const showModalTransf = (event) => {
    event.preventDefault();
    setShowTransf(true);
  };

  const showModalExclusao = (event) => {
    event.preventDefault();
    setShowExclusao(true);
  };

  function editMovimento(event, row) {
    event.preventDefault();

    for (var i = 0; i < row.allCells.length; i++) {
      if (row.allCells[i].column.Header === 'id_mov') {
        setIdMovimento(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'id') {
        setIdMovimentoDetalhe(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'Tipo') {
        setTipoLancamento(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'Dia') {
        setDataMovimento(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'id_categoria') {
        setCategoria(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'Valor') {
        setValor(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'Descrição') {
        setDescricao(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'fg_forma') {
        setFormaPagamento(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'Pagamento') {
        setSituacao(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'id_conta') {
        setConta(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'id_cartao') {
        setCartao(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'Parcela') {
        setTotalParcelas(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'tipo_pagamento') {
        setTipoPagamento(row.allCells[i].value);
      }
    }

    if (row.allCells[0].value === 'Transferência') {
      showModalTransf(event);
    } else {
      showModal(event);
    }
  }

  function deleteMovimento(event, row) {
    event.preventDefault();
    for (var i = 0; i < row.allCells.length; i++) {
      if (row.allCells[i].column.Header === 'id_mov') {
        setIdMovimento(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'id') {
        setIdMovimentoDetalhe(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'Tipo') {
        setTipoLancamento(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'Dia') {
        setDataMovimento(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'id_categoria') {
        setCategoria(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'Valor') {
        setValor(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'Descrição') {
        setDescricao(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'fg_forma') {
        setFormaPagamento(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'Pagamento') {
        setSituacao(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'id_conta') {
        setConta(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'id_cartao') {
        setCartao(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'Parcela') {
        setTotalParcelas(row.allCells[i].value);
      } else if (row.allCells[i].column.Header === 'tipo_pagamento') {
        setTipoPagamento(row.allCells[i].value);
      }
    }

    if (row.allCells[0].value === 'Transferência') {
      console.log('');
    } else {
      showModalExclusao(event);
    }
  }

  function removeTransferencia(id_transf) {
    const urlPostPortfolio = `https://33sa1tj8j4.execute-api.sa-east-1.amazonaws.com/producao/user-transf?id=${id_transf}&metodo=DELETE`;
    console.log(urlPostPortfolio);

    const requestOptionsPost = {
      method: 'POST',
      // body: json_api,
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json',
      }),
    };

    fetch(urlPostPortfolio, requestOptionsPost)
      .then((response) => {
        if (response.ok) {
          // console.log(response.json);

          window.location.reload(true);
          // setTimeout(() => setLoading(false), 1000);

          // return response.json();
        } else {
          throw new Error('Something went wrong ...');
        }
      })
      .catch((error) => console.log(error));
  }

  function getDataFatura(idFatura) {
    let faturaData = dataFatura.filter((dados) => dados.id_fatura === idFatura);
    dataFatura = dataFatura.sort(
      (a, b) =>
        parseFloat(a.data_compra.toString().replaceAll('-', '')) -
        parseFloat(b.data_compra.toString().replaceAll('-', '')),
    );
    return faturaData;
  }

  function getSVGSaldos(tipo) {
    if (tipo === 'Despesa') {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="red"
          className="bi bi-coin"
          viewBox="0 0 16 16"
        >
          <path d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9H5.5zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518l.087.02z" />
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
          <path d="M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11zm0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12z" />
        </svg>
      );
    } else if (tipo === 'Receita') {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="green"
          className="bi bi-coin"
          viewBox="0 0 16 16"
        >
          <path d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9H5.5zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518l.087.02z" />
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
          <path d="M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11zm0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12z" />
        </svg>
      );
    } else if (tipo === 'Investimentos') {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="blue"
          className="bi bi-graph-up-arrow"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M0 0h1v15h15v1H0V0Zm10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4.9l-3.613 4.417a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61L13.445 4H10.5a.5.5 0 0 1-.5-.5Z"
          />
        </svg>
      );
    } else {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="black"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z"
          />
        </svg>
      );
    }
  }

  function getContaCartao(row) {
    if (row.cells[14].value) {
      if (row.cells[13].value) {
        return row.cells[13].value;
      } else {
        return row.cells[14].value;
      }
    } else {
      return row.cells[13].value;
    }
  }

  function getTableDesktop() {
    return (
      <Table
        responsive
        // bordered
        hover
        size="sm"
        className={styleTabela.tabela}
        {...getTableProps()}
      >
        <thead>
          <tr>
            <th>Descrição</th>
            <th>R$</th>
            <th>Vencimento</th>
            <th>Situação</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <>
                <tr
                  {...row.getRowProps()}
                  id={row.cells[0].value}
                  key={row.cells[0].value}
                >
                  <td>
                    <div style={{ display: 'block' }}>
                      <div>
                        <span className={styles.spanDescricao}>
                          {row.cells[2].value +
                            ' ' +
                            row.allCells[15].value.replace('NA', '')}
                        </span>
                      </div>
                      <div>
                        <span className={styles.spanDetalhes}>
                          {getSVGSaldos(row.cells[0].value)}
                          {'  ' +
                            formataData(row.cells[1].value)
                              .toString()
                              .replace('/2023', '')
                              .replace('/2024', '') +
                            ' - ' +
                            row.cells[3].value}
                        </span>
                      </div>
                      <div>
                        {row.cells[3].value.toString().toUpperCase() ===
                        'TRANSFERÊNCIA' ? null : (
                          <span className={styles.spanDetalhes}>
                            {'Conta/Cartão: ' + getContaCartao(row)}
                          </span>
                        )}
                      </div>
                    </div>
                  </td>

                  <td>{formataMoedaCF(row.cells[4].value, hide)}</td>
                  <td>{formataData(row.cells[5].value)}</td>

                  {row.cells[3].value.toString().toUpperCase() ===
                  'TRANSFERÊNCIA' ? (
                    <td></td>
                  ) : (
                    <td>
                      <span className={styles.span}>
                        {row.cells[8].value === '1' ? (
                          <button
                            className={styles.btnEdit}
                            onClick={(e) => {
                              setAcao('PAGAR');
                              editMovimento(e, row);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="green"
                              className="bi bi-hand-thumbs-up-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M6.956 1.745C7.021.81 7.908.087 8.864.325l.261.066c.463.116.874.456 1.012.965.22.816.533 2.511.062 4.51a9.84 9.84 0 0 1 .443-.051c.713-.065 1.669-.072 2.516.21.518.173.994.681 1.2 1.273.184.532.16 1.162-.234 1.733.058.119.103.242.138.363.077.27.113.567.113.856 0 .289-.036.586-.113.856-.039.135-.09.273-.16.404.169.387.107.819-.003 1.148a3.163 3.163 0 0 1-.488.901c.054.152.076.312.076.465 0 .305-.089.625-.253.912C13.1 15.522 12.437 16 11.5 16H8c-.605 0-1.07-.081-1.466-.218a4.82 4.82 0 0 1-.97-.484l-.048-.03c-.504-.307-.999-.609-2.068-.722C2.682 14.464 2 13.846 2 13V9c0-.85.685-1.432 1.357-1.615.849-.232 1.574-.787 2.132-1.41.56-.627.914-1.28 1.039-1.639.199-.575.356-1.539.428-2.59z" />
                            </svg>
                          </button>
                        ) : (
                          <button
                            className={styles.btnEdit}
                            onClick={(e) => {
                              setAcao('PAGAR');
                              editMovimento(e, row);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="gray"
                              className="bi bi-hand-thumbs-down-fill"
                              viewBox="0 0 16 16"
                            >
                              <path d="M6.956 14.534c.065.936.952 1.659 1.908 1.42l.261-.065a1.378 1.378 0 0 0 1.012-.965c.22-.816.533-2.512.062-4.51.136.02.285.037.443.051.713.065 1.669.071 2.516-.211.518-.173.994-.68 1.2-1.272a1.896 1.896 0 0 0-.234-1.734c.058-.118.103-.242.138-.362.077-.27.113-.568.113-.856 0-.29-.036-.586-.113-.857a2.094 2.094 0 0 0-.16-.403c.169-.387.107-.82-.003-1.149a3.162 3.162 0 0 0-.488-.9c.054-.153.076-.313.076-.465a1.86 1.86 0 0 0-.253-.912C13.1.757 12.437.28 11.5.28H8c-.605 0-1.07.08-1.466.217a4.823 4.823 0 0 0-.97.485l-.048.029c-.504.308-.999.61-2.068.723C2.682 1.815 2 2.434 2 3.279v4c0 .851.685 1.433 1.357 1.616.849.232 1.574.787 2.132 1.41.56.626.914 1.28 1.039 1.638.199.575.356 1.54.428 2.591z" />
                            </svg>
                          </button>
                        )}
                      </span>
                    </td>
                  )}

                  <td>
                    <span className={styles.span}>
                      <button
                        disabled={row.allCells[18].value === '1' ? true : false}
                        className={styles.btnEdit}
                        onClick={(e) => {
                          setAcao('UPDATE');
                          editMovimento(e, row);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-pencil-square"
                          viewBox="0 0 16 16"
                        >
                          <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                          <path
                            fillRule="evenodd"
                            d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                          />
                        </svg>
                      </button>
                      <button
                        disabled={row.allCells[18].value === '1' ? true : false}
                        className={styles.btnEdit}
                        onClick={(e) => {
                          if (row.allCells[0].value === 'Transferência') {
                            if (
                              window.confirm(
                                'Tem certeza que deseja EXCLUIR a Transferência ' +
                                  row.cells[2].value +
                                  ' no valor de ' +
                                  formataMoeda(row.allCells[4].value) +
                                  '?',
                              )
                            ) {
                              removeTransferencia(
                                row.allCells[9].value.split('||')[0],
                              );
                            }
                          } else {
                            deleteMovimento(e, row);
                          }
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-trash"
                          viewBox="0 0 16 16"
                        >
                          <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                          <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                        </svg>
                      </button>
                    </span>
                  </td>
                </tr>
                {row.cells[12].value.length > 5 &&
                  filtro === 'data_vencimento' && (
                    <tr key={row.cells[0].value + '-' + 'details'}>
                      <td colSpan={6}>
                        <details className={styles.details}>
                          <summary>
                            <span className={styles.spanDescricao}>
                              Detalhes da Fatura
                            </span>
                          </summary>
                          <MovimentosListFatura
                            dataFatura={getDataFatura(row.cells[9].value)}
                            hide={hide}
                          />
                        </details>
                      </td>
                    </tr>
                  )}
              </>
            );
          })}
        </tbody>
      </Table>
    );
  }

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'right' }}>
        <table style={{ border: 'none' }}>
          <tbody>
            <tr style={{ border: 'none' }}>
              <td style={{ paddingRight: '10px' }}>
                {' '}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="red"
                  className="bi bi-coin"
                  viewBox="0 0 16 16"
                >
                  <path d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9H5.5zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518l.087.02z" />
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path d="M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11zm0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12z" />
                </svg>
              </td>
              <td
                style={{
                  paddingRight: '10px',
                  paddingLeft: '10px',
                }}
              >
                {' '}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="green"
                  className="bi bi-coin"
                  viewBox="0 0 16 16"
                >
                  <path d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9H5.5zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518l.087.02z" />
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path d="M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11zm0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12z" />
                </svg>
              </td>
              <td
                style={{
                  paddingLeft: '10px',
                }}
              >
                {' '}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="blue"
                  className="bi bi-graph-up-arrow"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M0 0h1v15h15v1H0V0Zm10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4.9l-3.613 4.417a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61L13.445 4H10.5a.5.5 0 0 1-.5-.5Z"
                  />
                </svg>
              </td>
            </tr>
            <tr style={{ border: 'none' }}>
              <td style={{ paddingRight: '10px' }}>
                <span className={styles.span}>
                  {formataMoedaCF(totalDespesa, hide)}
                </span>
              </td>
              <td
                style={{
                  paddingRight: '10px',
                  paddingLeft: '10px',
                }}
              >
                <span className={styles.span}>
                  {formataMoedaCF(totalReceita, hide)}
                </span>
              </td>
              <td
                style={{
                  paddingLeft: '10px',
                }}
              >
                <span className={styles.span}>
                  {formataMoedaCF(totalInvestimentos, hide)}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="search-container">
        <GlobalFilter
          filter={globalFilter}
          setFilter={setGlobalFilter}
          placeholder={'🔎 PESQUISA '}
        />
      </div>
      {getTableDesktop()}

      {show && (
        <ModalCadastroMovimento
          onClose={() => setShow(false)}
          show={show}
          idMovimento={idMovimento}
          idMovimentoDetalhe={idMovimentoDetalhe}
          dataMovimento={dataMovimento}
          categoria={categoria}
          totalParcelas={totalParcelas}
          conta={'pgto-' + tipoPagamento + '|' + conta}
          cartao={'pgto-' + tipoPagamento + '|' + cartao}
          valor={parseFloat(valor).toFixed(2)}
          formaPagamento={formaPagamento}
          situacao={situacao}
          descricao={descricao}
          acao={acao}
          is_fatura={false}
          tipoLancamento={tipoLancamento}
        />
      )}

      {showTransf && (
        <ModalTransf
          onClose={() => setShowTransf(false)}
          show={showTransf}
          idTransf={idMovimentoDetalhe.split('||')[0]}
          idContaOrigem={idMovimentoDetalhe.split('||')[1]}
          idContaDestino={idMovimentoDetalhe.split('||')[2]}
          dataTransf={dataMovimento}
          valor={parseFloat(valor).toFixed(2)}
        />
      )}

      {showExclusao && (
        <ModalExcluiMov
          onClose={() => setShowExclusao(false)}
          show={showExclusao}
          idMovimento={idMovimento}
          idMovimentoDetalhe={idMovimentoDetalhe}
          dataMovimento={dataMovimento}
          categoria={categoria}
          totalParcelas={totalParcelas}
          valor={parseFloat(valor).toFixed(2)}
          formaPagamento={formaPagamento}
          situacao={situacao}
          descricao={descricao}
          tipoLancamento={tipoLancamento}
        />
      )}
    </>
  );
};

export default MovimentosList;
