import React from 'react';
import AdsMoneytizerMediumRectangle from '../AdsMoneytizer/AdsMoneytizerMediumRectangle';
import AdsContent9 from './AdsContent9';
import AdsNobeta3 from '../AdsNobeta/AdsNobeta3';
import AdsenseResponsivo3 from '../Adsense/AdsenseResponsivo3';
import AdsenseContent2 from '../Adsense/AdsenseContent2';
import AdsenseContent3 from '../Adsense/AdsenseContent3';
import AdsHelper from './AdsHelper';
import stylesDiv from '../../Pages/_css/Div.module.css';

const AdsContent3 = (props) => {
  const [content1Visible, setContent1Visible] = React.useState(true);

  React.useEffect(() => {
    var path = '/22523725657/guardardinheiro.com.br/Guardardinheiro_Content3';
    var size = [
      [250, 250],
      [300, 250],
      [336, 280],
    ];
    var id = 'Content3';

    const googletag = window.googletag || {};
    googletag.cmd = googletag.cmd || [];

    googletag.cmd.push(function () {
      var mapping = googletag
        .sizeMapping()
        .addSize([0, 0], ['fluid', [250, 250], [300, 250], [336, 280]])
        .build();

      if (props.interests && props.interests.length > 0) {
        googletag
          .defineSlot(path, size, id)
          .setTargeting('test', 'lazyload')
          .defineSizeMapping(mapping)
          .setCollapseEmptyDiv(true)
          .setTargeting('interesses', props.interests)
          .setTargeting('palavrasChave', props.keywords)
          .setTargeting('hora_do_dia', new Date().getHours().toString())
          .addService(googletag.pubads());
      } else {
        googletag
          .defineSlot(path, size, id)
          .setTargeting('test', 'lazyload')
          .defineSizeMapping(mapping)
          .setCollapseEmptyDiv(true)
          .addService(googletag.pubads());
      }

      googletag.pubads().enableLazyLoad({
        fetchMarginPercent: 20,
        renderMarginPercent: 10,
        mobileScaling: 2.0,
      });

      googletag.pubads().enableSingleRequest();
      googletag.enableServices();
    });
    googletag.cmd.push(function () {
      // googletag.pubads().setTargeting('keywords', keywords);
      googletag.display(id);
    });

    const checkContent1Visibility = () => {
      const content1Element = document.getElementById('Content1');
      if (content1Element) {
        setContent1Visible(
          window.getComputedStyle(content1Element).display !== 'none',
        );
      }
    };

    checkContent1Visibility();
    // Optionally, set up an interval or mutation observer to re-check visibility if it may change dynamically
    const intervalId = setInterval(checkContent1Visibility, 2800);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <div className={stylesDiv.publicidade}>
        <span>Publicidade</span>
      </div>
      <div
        id="Content3"
        style={{ textAlign: 'center', marginTop: '5px', marginBottom: '5px' }}
      ></div>
      {!content1Visible && (
        <div>
          <AdsenseContent3 />
        </div>
      )}
    </>
  );
};
export default AdsContent3;
