import React, { useEffect } from 'react';

const AdsMoneytizerMediumRectangle = () => {
  useEffect(() => {
    // Carregar os scripts após a montagem do componente
    const loadScripts = () => {
      const script1 = document.createElement('script');
      script1.src = '//ads.themoneytizer.com/s/gen.js?type=19';
      script1.async = true;

      const script2 = document.createElement('script');
      script2.src =
        '//ads.themoneytizer.com/s/requestform.js?siteId=121684&formatId=19';
      script2.async = true;

      document.getElementById('121684-19').appendChild(script1);
      document.getElementById('121684-19').appendChild(script2);
    };

    loadScripts();
  }, []);

  return (
    <div style={{ textAlign: 'center', margin: '5px' }} id="121684-19">
      {/* Os scripts serão inseridos aqui */}
    </div>
  );
};

export default AdsMoneytizerMediumRectangle;
